<template>
  <div>

    <v-row style="padding: 0px !important;">
      <v-col cols="12" md="12" sm="12" style="text-align: left">
        <canvas id="canvas" style="float: left"></canvas>

        <h2 v-if="$vuetify.breakpoint.mobile"
            style="font-family: LexiaBold,'Khmer OS Muol Light';padding-top:20px;font-weight: normal !important;"> {{
            doc && doc.name || ""
          }}<br>
          {{ doc && doc.latinName || "" }}
        </h2>
        <h1 v-if="!$vuetify.breakpoint.mobile"
            style="font-family: LexiaBold,'Khmer OS Muol Light';padding-top:20px;font-weight: normal !important;"> {{
            doc && doc.name || ""
          }}<br>
          {{ doc && doc.latinName || "" }}
        </h1>
        <p>
          {{ $t('code') }} ៖ {{ doc && doc.code || "" }}
        </p>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row>
          <v-col v-if="$vuetify.breakpoint.mobile" cols="12" style="text-align: left">
            <h2 style="font-family: 'Khmer Os Muol Light' !important">
              {{ $t('associationInformation') }}
            </h2>
            <div style="padding-left: 20px">

              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalMember') }} ៖ {{ (doc && doc.totalLessThan60 || 0) + (doc && doc.totalOver60 || 0) }}
                , {{ $t("female") }} ៖ {{ (doc && doc.femaleLessThan60 || 0) + (doc && doc.femaleOver60 || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalLessThan60') }} ៖ {{ (doc && doc.totalLessThan60 || 0) }} , {{ $t("female") }} ៖
                {{ (doc && doc.femaleLessThan60 || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalOver60') }} ៖ {{ (doc && doc.totalOver60 || 0) }} , {{ $t("female") }} ៖ {{
                  (doc &&
                      doc.femaleOver60 || 0)
                }}
              </p>

              <br>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalDisability') }} ៖ {{ (doc && doc.totalDisability || 0) }} , {{ $t("female") }} ៖
                {{ (doc && doc.femaleDisability || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('equityCard1') }} ៖ {{ (doc && doc.equityCard1 || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('equityCard2') }} ៖ {{ (doc && doc.equityCard2 || 0) }}
              </p>

              <br>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('createdBy') }} ៖ {{ (doc && doc.createdBy || "") }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('yearOfCreation') }} ៖ {{ (doc && doc.yearCreated || "") }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('yearJoin') }} ៖ {{ (doc && doc.yearJoined || "") }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="text-align: left">
            <h2 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;font-weight: normal !important;">
              {{ $t('service') }}
            </h2>
            <div>
              <v-row>
                <v-col cols="12" :md="$vuetify.breakpoint.mobile ? '12' : '12'" sm="12" style="padding: 0px !important;"
                       class="d-flex flex-row justify-center" v-for="sDoc in service"
                       :key="sDoc.serviceId">
                  <ul :class="sDoc.value==='ដំណើរការល្អធម្មតា' ? 'custom-module-green' : sDoc.value==='ដំណើរការមិនសូវល្អ' ? 'custom-module-orange'  : 'custom-module-red' "
                      style="text-align: center;color: black;">
                    <li style="color: black">

                      <v-list-item style="padding: 0px !important;">
                        <v-list-item-icon style="margin: 0px !important;">
                          <img style="width: 100px;height: 100px;"
                               :src="getServiceURLById(sDoc.serviceId)"/>
                        </v-list-item-icon>

                        <v-list-item-content style="justify-content: center" v-if="$i18n.locale==='en'">
                          {{ getServiceEnById(sDoc.serviceId) }}
                        </v-list-item-content>

                        <v-list-item-content style="justify-content: center" v-if="$i18n.locale!='en'">
                          {{
                            getServiceById(sDoc.serviceId)
                          }}
                        </v-list-item-content>

                        <v-list-item-action style="margin: 0px !important;">
                          <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                               v-if="sDoc.value==='មិនទាន់មាន'"
                               src="@/assets/1.png"/>
                          <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                               v-if="sDoc.value==='ដំណើរការមិនសូវល្អ'" src="@/assets/2.png"/>
                          <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                               v-if="sDoc.value==='ដំណើរការល្អធម្មតា'" src="@/assets/3.png"/>
                        </v-list-item-action>
                      </v-list-item>

                      <!--                      <div style="float: left">
                                              <img style="width: 100px;height: 100px;"
                                                   :src="getServiceURLById(sDoc.serviceId)"/>
                                            </div>

                                            <div style="float: right">
                                              <img style="width: 60px;height: 30px;transform: rotate(270deg)" v-if="sDoc.value==='មិនទាន់មាន'"
                                                   src="@/assets/1.png"/>
                                              <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                                   v-if="sDoc.value==='ដំណើរការមិនសូវល្អ'" src="@/assets/2.png"/>
                                              <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                                   v-if="sDoc.value==='ដំណើរការល្អ'" src="@/assets/3.png"/>
                                            </div>
                                            <span style="font-family: Khmer OS Muol Light;width: 350px">{{
                                                getServiceById(sDoc.serviceId)
                                              }}<br>{{ getServiceEnById(sDoc.serviceId) }}
                                            </span>-->
                    </li>
                    <br>

                  </ul>

                </v-col>
              </v-row>
            </div>
          </v-col>

        </v-row>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" style="text-align: left">
            <h2 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;font-weight: normal !important;">
              {{ $t('associationInformation') }}
            </h2>
            <div style="padding-left: 20px">

              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalMember') }} ៖ {{ (doc && doc.totalLessThan60 || 0) + (doc && doc.totalOver60 || 0) }}
                , {{ $t("female") }} ៖ {{ (doc && doc.femaleLessThan60 || 0) + (doc && doc.femaleOver60 || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalLessThan60') }} ៖ {{ (doc && doc.totalLessThan60 || 0) }} , {{ $t("female") }} ៖
                {{ (doc && doc.femaleLessThan60 || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalOver60') }} ៖ {{ (doc && doc.totalOver60 || 0) }} , {{ $t("female") }} ៖ {{
                  (doc &&
                      doc.femaleOver60 || 0)
                }}
              </p>
              <br>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('totalDisability') }} ៖ {{ (doc && doc.totalDisability || 0) }} , {{ $t("female") }} ៖
                {{ (doc && doc.femaleDisability || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('equityCard1') }} ៖ {{ (doc && doc.equityCard1 || 0) }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('equityCard2') }} ៖ {{ (doc && doc.equityCard2 || 0) }}
              </p>
              <br>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('createdBy') }} ៖ {{ (doc && doc.createdBy || "") }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('yearOfCreation') }} ៖ {{ (doc && doc.yearCreated || "") }}
              </p>
              <p>
                <v-icon style="color: black">mdi-circle-small</v-icon>
                {{ $t('yearJoin') }} ៖ {{ (doc && doc.yearJoined || "") }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="text-align: left">
            <h2 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;font-weight: normal !important;">
              {{ $t('contact') }}
            </h2>
            <div style="padding-left: 20px">
              <div v-if="$i18n.locale!='en'">
                <p v-for="cDoc in commission" :key="cDoc._id">
                  <span v-if="cDoc && cDoc.gender==='1'">លោក </span><span
                    v-if="cDoc && cDoc.gender==='2'">លោកស្រី </span>{{
                    cDoc && cDoc.name || ""
                  }}<br>
                  {{ $t(cDoc && cDoc.positionDoc && cDoc.positionDoc.name || "") }}<br>
                  <!--                {{ cDoc && cDoc.phoneNumber || "" }}<br>-->
                </p>

              </div>
              <div v-if="$i18n.locale==='en'">

                <p v-for="cDoc in commission" :key="cDoc._id">
                  <span v-if="cDoc && cDoc.gender==='1'">Mr </span><span v-if="cDoc && cDoc.gender==='2'">Ms </span>{{
                    cDoc && cDoc.latinName || cDoc.name || ""
                  }}<br>
                  {{ $t(cDoc && cDoc.positionDoc && cDoc.positionDoc.latinName || cDoc.positionDoc.name || "") }}<br>
                  <!--                {{ cDoc && cDoc.phoneNumber || "" }}<br>-->
                </p>
              </div>


              <p>
                {{ $t('theTeam') }}: {{ phoneNumber }}<br>
                Telegram: <a :href="telegramLink" target="_blank" class="href">{{ telegramLink }}</a>
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="text-align: left;" v-if="!$vuetify.breakpoint.mobile">
            <h2 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;font-weight: normal !important;">
              {{ $t('location') }}
            </h2>
            <div style="padding-left: 20px">
              <v-tabs>
                <v-tab @click="changeLocation(doc && doc.associationLocation || '')">{{
                    $t('associationLocation')
                  }}
                </v-tab>
                <v-tab @click="changeLocation(doc && doc.communeHallLocation || '')">{{
                    $t('communeHallLocation')
                  }}
                </v-tab>
                <v-tab @click="changeLocation(doc && doc.healthCenterLocation || '')">{{
                    $t('healthCenterLocation')
                  }}
                </v-tab>
                <!--                <v-tab @click="changeLocation(doc && doc.administrationPostLocation || '')">
                                  {{ $t('administrationPostLocation') }}
                                </v-tab>-->
                <v-tab @click="changeLocation(doc && doc.pagodaLocation || '')">{{ $t('pagodaLocation') }}</v-tab>
                <!--                  <v-tab @click="changeLocation(doc && doc.primarySchoolLocation || '')">{{
                                      $t('primarySchoolLocation')
                                    }}
                                  </v-tab>
                                  <v-tab @click="changeLocation(doc && doc.secondarySchoolLocation || '')">
                                    {{ $t('secondarySchoolLocation') }}
                                  </v-tab>
                                  <v-tab @click="changeLocation(doc && doc.highSchoolLocation || '')">{{
                                      $t('highSchoolLocation')
                                    }}
                                  </v-tab>
                                  <v-tab @click="changeLocation(doc && doc.universityLocation || '')">{{
                                      $t('universityLocation')
                                    }}
                                  </v-tab>-->

              </v-tabs>
              <br>
              <div style="width: 100%" v-html="linkLocation"></div>

            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <div v-if="$vuetify.breakpoint.mobile">
      <h2 style="text-align: left;padding-left: 20px;font-family: 'Khmer Os Muol Light' !important">
        {{ $t('location') }}
      </h2>
      <div>
        <v-tabs>
          <v-tab @click="changeLocation(doc && doc.associationLocation || '')">{{
              $t('associationLocation')
            }}
          </v-tab>
          <v-tab @click="changeLocation(doc && doc.communeHallLocation || '')">{{
              $t('communeHallLocation')
            }}
          </v-tab>
          <v-tab @click="changeLocation(doc && doc.healthCenterLocation || '')">{{
              $t('healthCenterLocation')
            }}
          </v-tab>
          <!--          <v-tab @click="changeLocation(doc && doc.administrationPostLocation || '')">
                      {{ $t('administrationPostLocation') }}
                    </v-tab>-->
          <v-tab @click="changeLocation(doc && doc.pagodaLocation || '')">{{ $t('pagodaLocation') }}</v-tab>
          <!--                  <v-tab @click="changeLocation(doc && doc.primarySchoolLocation || '')">{{
                                $t('primarySchoolLocation')
                              }}
                            </v-tab>
                            <v-tab @click="changeLocation(doc && doc.secondarySchoolLocation || '')">
                              {{ $t('secondarySchoolLocation') }}
                            </v-tab>
                            <v-tab @click="changeLocation(doc && doc.highSchoolLocation || '')">{{
                                $t('highSchoolLocation')
                              }}
                            </v-tab>
                            <v-tab @click="changeLocation(doc && doc.universityLocation || '')">{{
                                $t('universityLocation')
                              }}
                            </v-tab>-->

        </v-tabs>
        <br>
        <div style="overflow-x: auto;width: 100%">
          <div style="max-width: 100%" v-html="linkLocation"></div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>

import MyMixin from "@/mixins/mixin"

//import axios from "axios";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {Constants} from "@/libs/constant";
import axios from "axios";
import QRCode from 'qrcode'

export default {
  name: "Association",
  mixins: [MyMixin],
  components: {},
  data() {

    return {
      index: null,
      model: null,
      dense: true,
      doc: {},
      service: [],
      commission: [],
      serviceList: [],
      linkLocation: "",
      telegramLink: "",
      phoneNumber: "",
      params: {
        provinceId: "",
        districtId: "",
        communeId: "",
        villageId: "",
      }
    };
  },
  mounted() {
    this.onResize();
    QRCode.toCanvas(document.getElementById('canvas'), window.location.href, function (error) {
      if (error) console.error(error)
    })
  },
  watch: {
    /*videoLink(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        vm.videoEmbedCode =
            "https://www.youtube.com/embed/" +
            val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
      }
    },*/
  },
  methods: {
    changeLocation(link) {
      this.linkLocation = link;
    },
    getServiceById(id) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return sDoc && sDoc.label || "";
    },
    getServiceURLById(id) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return sDoc && sDoc.url || "";
    },
    getServiceEnById(id) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return sDoc && sDoc.labelEn || "";
    },
    fetchAssociationById(id) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getAssociationById;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          id: id || ""
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.commission = (res.data.data && res.data.data.commission || []).filter(d => ['01', '02', '03'].indexOf(d.positionDoc && d.positionDoc.code || "") > -1);
                vm.doc = res.data.data && res.data.data.doc || {};
                vm.service = res.data.data && res.data.data.doc && res.data.data.doc.service || [];
                vm.linkLocation = vm.doc.associationLocation || "";
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },

    fetchBranchById() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getBranch;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          id: Constants.branchId
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                vm.phoneNumber = res.data.data.phoneNumber;
                vm.telegramLink = res.data.data.telegramLink;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    fetchService() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getService;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.serviceList = res.data.data || []
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },

  },

  created() {

    let id = this.$route.params.id;
    this.fetchService();
    this.fetchBranchById();
    this.fetchAssociationById(id);

  },
};
</script>


<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.member_counter {
  padding: 73px 0px 73px;
  background-image: -webkit-linear-gradient(0deg, lightblue 0%, #244a93 100%);
}

.changeColor:hover {
  color: darkgoldenrod;
  opacity: 1;
  cursor: pointer;
  elevation: 20deg;
}

.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}

.program :hover {
  elevation: 30deg;
  color: darkgoldenrod;
}

.imgZoom :hover {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoomActive {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoom1 :hover {
  transition: transform .20s;
  transform: scale(1.05);
}

.imgUp :hover {
  transition: transform .2s;
  margin-top: -10px;
}

.centered {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTitle {
  position: absolute;
  top: 107%;
  left: 50%;
  transform: translate(-50%, 0%);

}

.changeOnHover :hover {
  background: lightgreen;
  color: white;
}

.img-circle {
  border-radius: 50%;
}

.row {
  margin: 0px !important;
}


ul.custom-module {
}

ul.custom-module-green li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px green;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-orange li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;

  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px orange;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-red li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px red;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module li:hover {
  background: yellowgreen;
  border-radius: 20px;
  transform: scale(1);
  border: none;
  color: white;

}

ul.custom-module li.active-module {
  background: rgba(0, 59, 108, 0.8);
  border-radius: 20px;
  transform: scale(1);
  border: none;

}

i.v-icon:hover {
  color: white;
}


</style>
